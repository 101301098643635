<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.stock_details_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Godown" vid="godown_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="godown_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerReport.godown_name') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.godown_id"
                      :options="godownInfoList"
                      id="godown_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Fertilizer"  vid="fertilizer_id"  rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="fertilizer_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerConfig.fertilizer_name')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fertilizer_id"
                      :options="fertilizerNameList"
                      id="fertilizer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-for="from_date"
                >
                <template v-slot:label>
                    {{$t('globalTrans.from_date')}}  <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    class="fromDate"
                    v-model="search.date_from"
                    :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-for="to_date"
                >
                <template v-slot:label>
                      {{$t('globalTrans.to_date')}}  <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    class="toDate"
                      v-model="search.date_to"
                    :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col></b-col>
                <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.stock_details_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                    <b-col>
                        <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                {{ $t('fertilizerReport.stock_details_report') }}
                        </list-report-head>
                    </b-col>
                    <div class="text-center">
                        <table style="width:100%;color:black;">
                            <tr>
                                <td align="right" style="width:15%">{{ $t('fertilizerReport.fertilizer') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:13%">{{ $i18n.locale === 'bn' ? search.fertilizer_name_bn : search.fertilizer_name }}</td>
                                <td align="right" style="width:15%">{{ $t('globalTrans.from_date') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:18%">{{ search.date_from|dateFormat }}</td>
                                <td align="right" style="width:15%">{{ $t('globalTrans.to_date') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:18%">{{ search.date_to|dateFormat }}</td>
                            </tr>
                            <tr>
                                <td align="right" style="width:15%">{{ $t('fertilizerReport.godown') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:13%">{{ $i18n.locale === 'bn' ? search.godown_name_bn : search.godown_name }}</td>
                                <td align="right" style="width:15%">{{ $t('fertilizerReport.sale_center_name') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:18%">{{ $i18n.locale === 'bn' ? search.sale_center_bn : search.sale_center }}</td>
                                <td align="right" style="width:15%">{{ $t('fertilizerReport.region_name') }}</td>
                                <td align="center" style="width:2%">:</td>
                                <td align="left" style="width:18%">{{ $i18n.locale === 'bn' ? search.region_name_bn : search.region_name }}</td>
                            </tr>
                        </table>
                    </div>
                <b-row class="mt-5">
                  <b-col md="12" mt-3 class="table-responsive">
                    <b-overlay v-show="showData">
                      <div>
                        <b-table-simple bordered hover small caption-top responsive>
                          <colgroup><col><col></colgroup>
                          <colgroup><col><col><col></colgroup>
                          <colgroup><col><col></colgroup>
                          <b-thead>
                            <b-tr>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.date') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.opening_stock') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.receive_details') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.challan_fertilizer_amount') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.fertilizer_receive_amount') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.difference_amount') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.sale_details') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.sale_distribution') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.sent_elsewhere') }}</b-th>
                              <b-th colspan="2" class="text-center">{{ $t('fertilizerReport.final_stock') }}</b-th>
                              <b-th rowspan="2" class="text-center">{{ $t('fertilizerReport.comments') }}</b-th>
                            </b-tr>
                            <b-tr>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                            <b-th class="text-right">{{ $t('fertilizerReport.bag') }}</b-th>
                            <b-th  class="text-right">{{ $t('fertilizerReport.m_ton') }}</b-th>
                          </b-tr>
                          </b-thead>
                          <b-tr v-for="(stockInfo, index) in stockDetailsData" :key="index">
                            <b-td class="text-center">{{(stockInfo.date) | dateFormat}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.opening*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.opening)}}</b-td>
                            <b-td class="text-center">{{$t('fertilizerReport.according_to_received_report') }}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.delivered_total*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.delivered_total)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.received_total*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.received_total)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.difference_total*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.difference_total)}}</b-td>
                            <b-td class="text-center">{{$t('fertilizerReport.according_to_sales_report') }}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.sold*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.sold)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.inter_delivered_from*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.inter_delivered_from)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.final_stock*20)}}</b-td>
                            <b-td class="text-right">{{$n(stockInfo.final_stock)}}</b-td>
                            <b-td class="text-right"></b-td>
                          </b-tr>
                        </b-table-simple>
                    </div>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, stockDetailsReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      withFerlizer: false,
      search: {
        godown_id: 0,
        fertilizer_id: 0,
        date_from: '',
        date_to: '',
        fertilizer_name: '',
        fertilizer_name_bn: '',
        sale_center: '',
        sale_center_bn: '',
        godown_name: '',
        godown_name_bn: ''
      },
      stockDetailsData: [],
      from_date: [],
      date_to: [],
      organization: [],
      tsptotal: 0,
      rows: [],
      fertilizer_name: '',
      fertilizer_name_bn: '',
      godown_name: '',
      godown_name_bn: '',
      region_name: '',
      region_name_bn: '',
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('fertilizerReport.ministry_allocation_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    flatpickr('.toDate', {})
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
        const fertilizerList = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === this.search.fertilizer_id)
        this.search.fertilizer_name = fertilizerList.text_en
        this.search.fertilizer_name_bn = fertilizerList.text_bn
        const godownList = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.find(item => item.value === this.search.godown_id)
        this.search.godown_name = godownList.text_en
        this.search.godown_name_bn = godownList.text_bn
        const regionList = this.$store.state.commonObj.officeList.find(item => item.value === godownList.region_id)
        this.search.region_name = regionList !== undefined ? regionList.text_en : ''
        this.search.region_name_bn = regionList !== undefined ? regionList.text_bn : ''
        const saleCenterList = this.$store.state.commonObj.officeList.find(item => item.value === godownList.sales_center_id)
        this.search.sale_center = saleCenterList !== undefined ? saleCenterList.text_en : ''
        this.search.sale_center_bn = saleCenterList !== undefined ? saleCenterList.text_bn : ''
        this.showData = true
        this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, stockDetailsReport, params).then(response => {
        if (response.success) {
            this.stockDetailsData = response.data
          }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
      const allDatas = []
      data.forEach((element, key) => {
        const dealerObject = dealerList.find(dealer => dealer.value === element.dealer_id)
        element.dealer_name = dealerObject !== undefined ? dealerObject.text_en : ''
        element.dealer_name_bn = dealerObject !== undefined ? dealerObject.text_bn : ''

        allDatas[key] = element
      })
      return allDatas
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.stock_details_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.stockDetailsData, this, this.search)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
